import React from "react"
/** @jsx jsx */
import { jsx, Container } from "theme-ui"
import BlockContent from "@sanity/block-content-to-react"

import { mediaQueries } from "../gatsby-plugin-theme-ui/media-queries"
import { SectionHeading } from "./section-heading"

export const SectionFaq = ({ section }) => {
  if (!section) {
    return null
  }

  const { heading, faqs } = section

  return (
    <section
      sx={{
        px: 4,
        py: [4, 4, 4, 5],
      }}
    >
      <SectionHeading heading={heading} />
      <Container>
        {faqs?.length > 0 && (
          <ul
            sx={{
              listStyle: `none`,
              margin: 0,
              padding: 0,
              display: `flex`,
              flexDirection: `column`,
            }}
          >
            {faqs.map(faq => {
              return (
                <li
                  key={faq._key}
                  sx={{
                    my: 4,
                    [mediaQueries.md]: {
                      mx: 4,
                    },
                  }}
                >
                  <h3>{faq.question}</h3>
                  {faq._rawAnswer && (
                    <BlockContent
                      blocks={faq._rawAnswer}
                      renderContainerOnSingleChild={true}
                      sx={{
                        p: {
                          fontSize: 2,
                          opacity: 0.8,
                        },
                      }}
                    />
                  )}
                </li>
              )
            })}
          </ul>
        )}
      </Container>
    </section>
  )
}
