import React from 'react'
import { HeroWithImages } from './hero-with-images'
import { HeroWithTextOnly } from './hero-with-text-only'

export const HeroSelector = ({ hero }) => {

  switch(hero._type) {
    case 'heroWithImages': {
      return <HeroWithImages hero={hero} />
    }
    case 'heroWithTextOnly': {
      return <HeroWithTextOnly hero={hero} />
    }
    default:
      return null
  }
}