import React from "react"
/** @jsx jsx */
import { jsx, Container } from "theme-ui"
import { SectionHeading } from "./section-heading"
import { ButtonSelector } from "./button-selector"

export const SectionCallToAction = ({ section }) => {
  if (!section) {
    return null
  }

  const { heading, buttons } = section
  return (
    <section
      sx={{
        px: 4,
        py: [4, 4, 4, `80px`],
      }}
    >
      <SectionHeading heading={heading} />
      <Container>
        {buttons?.length > 0 && (
          <div
            sx={{
              display: `flex`,
              justifyContent: `center`,
              mt: 4,
            }}
          >
            {buttons.map(button => {
              return (
                <ButtonSelector
                  button={button}
                  key={button._key}
                  sx={{ ":not(:first-of-type)": { ml: 3 } }}
                />
              )
            })}
          </div>
        )}
      </Container>
    </section>
  )
}
