import React from "react"
/** @jsx jsx */
import { jsx, Container } from "theme-ui"

import BlockContent from "@sanity/block-content-to-react"
import { ButtonSelector } from "./button-selector"
import { mediaQueries } from "../gatsby-plugin-theme-ui/media-queries"
import { breakpoints } from "../gatsby-plugin-theme-ui/breakpoints"

export const HeroWithTextOnly = ({ hero }) => {
  const { heading, buttons } = hero

  return (
    <section
      sx={{
        position: `relative`,
      }}
    >
      <Container
        sx={{
          [mediaQueries.lg]: {
            maxWidth: breakpoints.xxxl,
          },
        }}
      >
        <div>
          <Container
            px={[4]}
            pt={[6, 6, 6, 6, 6]}
            pb={[0]}
            sx={{
              [mediaQueries.lg]: {
                maxWidth: breakpoints.sm,
              },
            }}
          >
            {heading?._rawTitle && (
              <BlockContent
                blocks={heading?._rawTitle}
                serializers={serializers}
                renderContainerOnSingleChild={true}
              />
            )}
            {heading?._rawSubtitle && (
              <BlockContent
                blocks={heading?._rawSubtitle}
                renderContainerOnSingleChild={true}
                sx={{
                  textAlign: `center`,
                }}
              />
            )}
            {buttons?.length > 0 && (
              <div
                sx={{
                  display: `flex`,
                  mt: 4,
                }}
              >
                {buttons.map(button => {
                  return (
                    <ButtonSelector
                      button={button}
                      key={button._key}
                      sx={{ ":not(:first-of-type)": { ml: 3 } }}
                    />
                  )
                })}
              </div>
            )}
          </Container>
        </div>
      </Container>
    </section>
  )
}

const serializers = {
  container: props => <h1 sx={{ textAlign: `center` }}>{props.children}</h1>,
  marks: {
    strong: props => <span sx={{ color: "primary" }}>{props.children}</span>,
  },
  types: {
    block(props) {
      switch (props.node.style) {
        default:
          return <span>{props.children}</span>
      }
    },
  },
}
