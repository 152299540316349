import React from 'react'
/** @jsx jsx */
import { jsx, Container } from 'theme-ui'
import { GatsbyImage } from "gatsby-plugin-image"

import { FiChevronLeft, FiChevronRight } from "react-icons/fi"
import { CarouselProvider, Slide, Slider, ButtonBack, ButtonNext, DotGroup } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"

import BlockContent from "@sanity/block-content-to-react"
import { ButtonSelector } from './button-selector'
import { mediaQueries } from '../gatsby-plugin-theme-ui/media-queries'
import { breakpoints } from '../gatsby-plugin-theme-ui/breakpoints'


export const HeroWithImages = ({ hero }) => {
  const { heading, buttons, images } = hero

  return (
    <section
      sx={{
        position: `relative`,
      }}
    >
      <Container
        sx={{
          [mediaQueries.lg]: {
            maxWidth: breakpoints.xxxl,
          },
        }}
      >
        <div
          sx={{
            display: `flex`,
            flexDirection: `column-reverse`,
            [mediaQueries.md]: {
              display: `grid`,
              gridTemplateColumns: `1fr 1fr`,
            },
          }}
        >
          <Container
            px={[4]}
            py={[5, 5, 5]}
            pt={[5, 5, 6, 6, 6, 7]}
            sx={{
              [mediaQueries.lg]: {
                maxWidth: breakpoints.sm,
              },
            }}
          >
            {heading?._rawTitle && (
              <BlockContent
                blocks={heading?._rawTitle}
                serializers={serializers}
                renderContainerOnSingleChild={true}
              />
            )}
            {heading?._rawSubtitle && (
              <BlockContent
                blocks={heading?._rawSubtitle}
                renderContainerOnSingleChild={true}
              />
            )}
            {buttons?.length > 0 && (
              <div
                sx={{
                  display: `flex`,
                  mt: 4,
                }}
              >
                {buttons.map(button => {
                  return (
                    <ButtonSelector
                      button={button}
                      key={button._key}
                      sx={{ ":not(:first-of-type)": { ml: 3 } }}
                    />
                  )
                })}
              </div>
            )}
          </Container>
          {images && (
            <GalleryCarousel
              images={images}
            />
          )}
        </div>
      </Container>
    </section>
  )
}

const GalleryCarousel = ({ images }) => {
  if(!images?.length > 0) {
    return null
  }

  const naturalHeight = images[0]?.image?.asset?.metadata?.dimensions?.height
  const naturalWidth = images[0]?.image?.asset?.metadata?.dimensions?.width

  return (
    <div
      sx={{
        position: `relative`,
        maxHeight: `100vh`,
        overflow: `hidden`,
      }}
    >
      <CarouselProvider
        totalSlides={images.length}
        naturalSlideHeight={naturalHeight}
        naturalSlideWidth={naturalWidth}
        infinite
        sx={{
          position: `relative`,
        }}
      >
        <Slider>
          {images.map((item, idx) => {
            return (
              <Slide
                key={item._key}
                sx={{
                  ".carousel__inner-slide": {
                    display: `flex`,
                    flexDirection: `column`,
                    justifyContent: `center`,
                  },
                }}
              >
                {item.image?.asset?.gatsbyImageData && (
                  <div>
                    <GatsbyImage
                      alt={item.altText}
                      image={item.image.asset.gatsbyImageData}
                      loading={idx === 0 ? `eager` : `lazy`}
                    />
                  </div>
                )}
              </Slide>
            )
          })}
        </Slider>
        <div
          sx={{
            position: `absolute`,
            bottom: 0,
            right: 0,
            display: `flex`,
          }}
        >
          <ButtonBack sx={buttonStyle}>
            <FiChevronLeft sx={{ width: 18, height: 18 }} />
          </ButtonBack>
          <ButtonNext sx={buttonStyle}>
            <FiChevronRight sx={{ width: 18, height: 18 }} />
          </ButtonNext>
        </div>
        <DotGroup
          dotNumbers={true}
          sx={{
            display: `flex`,
            position: `absolute`,
            bottom: 0,
            left: 0,
            ".carousel__dot": {
              width: 36,
              height: 36,
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`,
              fontSize: 0,
              border: `none`,
              background: `unset`,
              color: `white`,
              fontWeight: `bold`,
              [mediaQueries.md]: {
                width: 48,
                height: 48,
              },
            },
            ".carousel__dot--selected": {
              bg: `primary`,
            },
          }}
        />
      </CarouselProvider>
    </div>
  )
}

const buttonStyle = {
  bg: `background`,
  color: `text`,
  border: `none`,
  margin: 0,
  outline: `none`,
  width: 36,
  height: 36,
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  [mediaQueries.md]: {
    width: 48,
    height: 48,
  }
}

const serializers = {
  container: props => <h1>{props.children}</h1>,
  marks: {
    strong: props => <span sx={{ color: 'primary' }}>{props.children}</span>,
  },
  types: {
    block(props) {
      switch (props.node.style) {
        default:
          return <span>{props.children}</span>
      }
    },
  },
}