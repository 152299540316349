import React, { useState, useCallback } from "react"
/** @jsx jsx */
import { jsx, Container } from "theme-ui"
import { GatsbyImage } from 'gatsby-plugin-image'
import Gallery from "react-photo-gallery"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import { SectionHeading } from "./section-heading"

export const SectionGallery = ({ section }) => {
  if (!section) {
    return null
  }

  const { heading, images } = section

  return (
    <section
      sx={{
        px: 4,
        py: [4, 4, 4, 5],
      }}
    >
      <SectionHeading heading={heading} />
      {images && (
        <Container
          sx={{
            mt: [4, 4, 4, 5],
          }}
        >
          <PhotoGallery photos={images} margin={8} />
        </Container>
      )}
    </section>
  )
}

const Photo = ({ photo, margin, index, top, left, onClick }) => {
  const handleClick = event => {
    onClick(event, { photo, index })
  }

  return (
    <figure
      role="button"
      tabIndex={0}
      aria-label="Open image in a lightbox"
      sx={{
        m: `${margin}px`,
        height: photo.height,
        width: photo.width,
        position: `relative`,
        overflow: `hidden`,
        cursor: `pointer`,
      }}
      onClick={onClick ? handleClick : null}
    >
      <GatsbyImage image={photo.gatsbyImageData} alt={photo.alt} />
    </figure>
  )
}

export const PhotoGallery = ({
  photos,
  className,
  margin,
  targetRowHeight,
}) => {

  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  let galleryPhotos = []

  if (!photos) {
    return null
  }

  for (var i = 0; i < photos.length; i++) {
    // this stops the front end from breaking when an image is about to be added
    // on sanity.
    if (!photos[i].image?.asset) {
      break
    }

    galleryPhotos[i] = {
      alt: photos[i].altText,
      caption: photos[i].caption,
      height: 1,
      width: photos[i].image?.asset?.metadata?.dimensions?.aspectRatio,
      gatsbyImageData: photos[i].image?.asset?.gatsbyImageData,
      src: photos[i].image?.asset?.lightBox?.images?.fallback?.src,
    }
  }

  return (
    <div
      className={className}
      sx={{
        mx: `-${margin}px`,
      }}
    >
      {galleryPhotos.length > 0 ? (
        <Gallery
          photos={galleryPhotos}
          targetRowHeight={targetRowHeight}
          margin={margin}
          renderImage={({ index, left, top, key, photo, onClick }) => {
            return (
              <Photo
                key={key}
                index={index}
                photo={photo}
                left={left}
                top={top}
                margin={margin}
                onClick={onClick}
              />
            )
          }}
          onClick={openLightbox}
        />
      ) : (
        <span>No photos provided</span>
      )}
      {galleryPhotos.length > 0 && (
        <div
          sx={{
            ".lightbox-wrapper": {
              ".ril__image": {
                height: `900px`,
              },
            },
          }}
        >
          {viewerIsOpen ? (
            <Lightbox
              mainSrc={galleryPhotos[currentImage].src}
              nextSrc={
                galleryPhotos[(currentImage + 1) % galleryPhotos.length].src
              }
              imageTitle={galleryPhotos[currentImage].title}
              prevSrc={
                galleryPhotos[
                  (currentImage + galleryPhotos.length - 1) %
                    galleryPhotos.length
                ].src
              }
              onCloseRequest={closeLightbox}
              onMovePrevRequest={() =>
                setCurrentImage(
                  (currentImage + galleryPhotos.length - 1) %
                    galleryPhotos.length
                )
              }
              onMoveNextRequest={() =>
                setCurrentImage((currentImage + 1) % galleryPhotos.length)
              }
              imagePadding={64}
              enableZoom={false}
            />
          ) : null}
        </div>
      )}
    </div>
  )
}
