import React from "react"
/** @jsx jsx */
import { jsx, Container } from "theme-ui"
import { ToastContainer, toast } from "react-toastify"

import "react-toastify/dist/ReactToastify.css"

import { breakpoints } from "../gatsby-plugin-theme-ui/breakpoints"
import { mediaQueries } from "../gatsby-plugin-theme-ui/media-queries"
import { SectionHeading } from "./section-heading"



export const SectionContactForm = ({ section }) => {
  if (!section) {
    return null
  }

  const { heading, form } = section
  return (
    <section
      sx={{
        [mediaQueries.md]: {
          maxHeight: `100vh`,
        },
      }}
    >
      <div
        sx={{
          display: `grid`,
          [mediaQueries.lg]: {
            gridTemplateColumns: `1fr 1fr`,
          },
        }}
      >
        <Container
          sx={{
            maxWidth: breakpoints.lg,
            mt: 5,
            px: [4, 4, 4, 5, 6],
            py: [5, 5, 5, 6, 6],
          }}
        >
          <SectionHeading
            heading={heading}
            sx={{
              marginLeft: `0`,
              h2: { textAlign: `left !important`, fontSize: [5,5,5,7] },
            }}
          />
          <Container sx={{ maxWidth: breakpoints.sm, marginLeft: `0`, mt: 5 }}>
            {form && <FormBuilder form={form} />}
          </Container>
        </Container>
        <div>
          <div
            sx={{
              position: `relative`,
              [mediaQueries.lg]: {
                height: `100%`,
                  iframe: {
                  height: `100%`,
                  cursor: `default`,
                }
              },
              "::after": {
                content: `""`,
                position: `absolute`,
                top: 0,
                left: 0,
                width: `100%`,
                height: `100%`,
                bg: `black`,
                opacity: 0.2,
              },
            }}
            dangerouslySetInnerHTML={{
              __html: `<iframe src="https://snazzymaps.com/embed/276152" width="100%" height="600px" style="border:none;"></iframe>`,
            }}
          />
        </div>
      </div>
    </section>
  )
}

const FormBuilder = ({ form }) => {
  if(!form) {
    return null
  }

  const { _rawFields } = form

  const fieldGroupStyle = {
    display: `flex`,
    flexDirection: `column`,
    mb: 4,
  }

  const inputStyle = {
    border: 0,
    bg: `unset`,
    borderBottom: `2px solid`,
    borderColor: `#ffffff1a`,
    color: `text`,
    fontSize: 2,
    p: 2,
  }

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleSubmit = event => {
    event.preventDefault()

    const elementsArray = [...event.target.elements]
    const form = event.target

    const formData = elementsArray.reduce((acc, elem) => {
      if(elem.id) {
        acc[elem.id] = elem.value
      }

      return acc
    }, {})
    
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": event.target.getAttribute("name"),
        ...formData,
      }),
    })
      .then(() => {
        toast.success("Thanks! We'll get in touch shortly.")
        form.reset()
      })
      .catch(error => toast.error("We didn't get your message"))
  }

  return (
    <div>
      {_rawFields?.length > 0 && (
        <form data-netlify="true" name={form.title} onSubmit={handleSubmit}>
          <input type="hidden" name="form-name" value={form.title} />
          {_rawFields.map(field => {
            return (
              <div key={field._key} sx={fieldGroupStyle}>
                <label sx={{ mb: 3 }}>{field.label}</label>
                <InputSelector
                  type={field.type || `text`}
                  name={field.name}
                  required={field.isRequired}
                  id={field.name}
                  sx={inputStyle}
                />
              </div>
            )
          })}
          <input
            value="Submit"
            type="submit"
            sx={{
              variant: `buttons.primary`,
              border: `none`,
              textAlign: `center`,
              textDecoration: `none`,
              display: `block`,
              py: [2],
              px: [3],
              fontWeight: `bold`,
              fontSize: 1,
              cursor: `pointer`,
            }}
          />
        </form>
      )}
      <ToastContainer
        position="top-right"
        autoClose={false}
        newestOnTop={false}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable={false}
        sx={{
          ".Toastify__toast": {
            px: 3,
            py: 2,
            mb: 2,
            [mediaQueries.md]: {
              mb: 2,
            },
          },
          ".Toastify__close-button": {
            position: `absolute`,
            right: 2,
          },
        }}
      />
    </div>
  )
}

const InputSelector = ({ type, ...rest }) => {
  switch(type) {
    case 'textarea':
      return (
        <textarea
          name="message"
          rows={3}
          {...rest}
        />
      )
    default:
      return <input {...rest} />
  }
}
