import React from "react"
/** @jsx jsx */
import { jsx, Container } from "theme-ui"
import BlockContent from "@sanity/block-content-to-react"
import { mediaQueries } from "../gatsby-plugin-theme-ui/media-queries"
import { SectionHeading } from "./section-heading"
import { ButtonLink } from "./button-link"

export const SectionPricing = ({ section }) => {
  if (!section) {
    return null
  }

  const { pricingCards, heading } = section

  return (
    <section
      sx={{
        px: 4,
        py: [4, 4, 4, 5],
      }}
    >
      <SectionHeading heading={heading} />
      <Container variant="largeContainer">
        {pricingCards?.length > 0 && (
          <ul
            sx={{
              m: 0,
              mt: 5,
              listStyle: `none`,
              display: `grid`,
              gridGap: 4,
              [mediaQueries.md]: {
                justifyContent: `center`,
                gridTemplateColumns: `repeat(auto-fit, 350px)`,
              },
            }}
          >
            {pricingCards.map((pricingCard, id) => {
              return (
                <li
                  key={pricingCard._key}
                  sx={{
                    py: 5,
                    px: 4,
                    mb: 0,
                    display: `flex`,
                    flexDirection: `column`,
                    ":nth-of-type(odd)": {
                      bg: `primary`,
                      border: `none`,
                      [mediaQueries.md]: {
                        bg: "unset",
                        border: `1px solid`,
                        borderColor: `mute`,
                      },
                    },
                    [mediaQueries.md]: {
                      px: 4,
                      ":nth-of-type(even)": {
                        border: `none`,
                        bg: `primary`,
                      },
                    },
                  }}
                >
                  <div
                    sx={{
                      display: `flex`,
                      justifyContent: `space-between`,
                      alignItems: `center`,
                      width: `100%`,
                      mb: 4,
                    }}
                  >
                    <div>
                      <h3 sx={{ m: 0, flex: 1 }}>{pricingCard.title}</h3>
                    </div>
                    <p
                      sx={{
                        m: 0,
                        width: `33%`,
                        borderLeft: `1px solid`,
                        textAlign: `right`,
                      }}
                    >
                      ${pricingCard.price}
                    </p>
                  </div>
                  <div
                    sx={{
                      flex: 1,
                    }}
                  >
                    {pricingCard.features?.length > 0 && (
                      <ul sx={{ listStyle: `none`, ml: 0 }}>
                        {pricingCard.features.map((feature, id) => {
                          return (
                            <li key={`${pricingCard._key}-${id}`}>
                              <span>{feature}</span>
                            </li>
                          )
                        })}
                      </ul>
                    )}
                  </div>
                  {pricingCard?._rawDescription && (
                    <div sx={{ mt: 5 }}>
                      <ButtonLink
                        button={{ label: `Select package`, theme: id !== 1 ? `primary` : `light` }}
                        sx={{
                          py: 3,
                        }}
                      />
                      <div sx={{ opacity: 0.8, p: { fontSize: 1 } }}>
                        <BlockContent blocks={pricingCard._rawDescription} />
                      </div>
                    </div>
                  )}
                </li>
              )
            })}
          </ul>
        )}
      </Container>
    </section>
  )
}
