import React from 'react'
import { SectionBrandList } from './section-brand-list'
import { SectionCardList } from './section-card-list'
import { SectionClientReviews } from './section-client-reviews'
import { SectionGallery } from "./section-gallery"
import { SectionCallToAction } from './section-call-to-action'
import { SectionContactForm } from "./section-contact-form"
import { SectionFaq } from './section-faq'
import { SectionPricing } from './section-pricing'

export const SectionSelector = ({ section }) => {
  switch(section._type) {
    case 'sectionCardList': {
      return <SectionCardList section={section} />
    }
    case 'sectionBrandList': {
      return <SectionBrandList section={section} />
    }
    case 'sectionClientReviews': {
      return <SectionClientReviews section={section} />
    }
    case 'sectionGallery': {
      return <SectionGallery section={section} />
    }
    case 'sectionCallToAction': {
      return <SectionCallToAction section={section} />
    }
    case 'sectionContactForm': {
      return <SectionContactForm section={section} />
    }
    case 'sectionFaq': {
      return <SectionFaq section={section} />
    }
    case 'sectionPricing': {
      return <SectionPricing section={section} />
    }
    default:
      return null
  }
}