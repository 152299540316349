import React from 'react'
/** @jsx jsx */
import { jsx, Container } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import BlockContent from "@sanity/block-content-to-react"

import { Link } from 'gatsby'
import { SectionHeading } from './section-heading'
import { mediaQueries } from '../gatsby-plugin-theme-ui/media-queries'

export const SectionCardList = ({ section }) => {
  const { heading, cards, layout, settings } = section

  const theme = {
    dark: {
      color: `white`,
      background: `black`
    },
    light: {
      color: `black`,
      background: `white`,
    }
  }

  return (
    <section
      sx={{
        px: 4,
        py: [4, 4, 4, `80px`],
        variant: `section.theme.${settings?.theme}`,
      }}
    >
      <SectionHeading heading={heading} />
      <CardListSelector layout={layout} cards={cards} />
    </section>
  )
}


const CardListSelector = ({ layout, cards }) => {
  switch (layout) {
    case "grid": {
      return <GridCollection cards={cards} />
    }
    case "numbered": {
      return <NumberedCollection cards={cards} />
    }
    default:
      return <DefaultCollection cards={cards} />
  }
}

const DefaultCollection = ({ cards }) => {
  if(!cards?.length > 0) {
    return null
  }

  return (
    <>
      <ul
        sx={{
          listStyle: `none`,
          margin: 0,
          py: 5,
          display: `grid`,
          gridColumnGap: 5,
          gridRowGap: 4,
          justifyContent: `center`,
          [mediaQueries.md]: {
            gridTemplateColumns: `repeat(auto-fit, 350px)`,
          },
        }}
      >
        {cards.map(card => {
          return (
            <li key={card._key} sx={{ m: 0 }}>
              <div>
                {card.media?.image?.asset?.gatsbyImageData && (
                  <div
                    sx={{
                      mb: 4,
                      width: `80%`,
                      mx: [`auto`, `auto`, `auto`, `0`],
                    }}
                  >
                    <GatsbyImage
                      image={card.media.image.asset.gatsbyImageData}
                      alt={card.media.altText}
                    />
                  </div>
                )}
                <h3
                  sx={{
                    fontSize: 3,
                    letterSpacing: `1px`,
                  }}
                >
                  {card.title}
                </h3>
                {card._rawDescription && (
                  <BlockContent
                    blocks={card._rawDescription}
                    renderContainerOnSingleChild={true}
                    sx={{
                      p: {
                        fontSize: 1,
                        opacity: 0.8,
                      },
                    }}
                  />
                )}
              </div>
            </li>
          )
        })}
      </ul>
    </>
  )
}

const NumberedCollection = ({ cards }) => {
  if (!cards?.length > 0) {
    return null
  }

  return (
    <>
      <ul
        sx={{
          listStyle: `none`,
          margin: 0,
          py: 5,
          display: `grid`,
          gridColumnGap: 5,
          gridRowGap: 4,
          justifyContent: `center`,
          [mediaQueries.md]: {
            gridTemplateColumns: `repeat(auto-fit, 320px)`,
          },
        }}
      >
        {cards.map((card, id) => {
          const number = id + 1
          return (
            <li key={card._key} sx={{ m: 0 }}>
              <div
                sx={{
                  position: `relative`,
                }}
              >
                <span sx={{ opacity: 0.25, fontSize: 7, fontWeight: `bold` }}>
                  0{number}
                </span>
                <h3
                  sx={{
                    fontSize: 3,
                    letterSpacing: `1px`,
                  }}
                >
                  {card.title}
                </h3>
                {card._rawDescription && (
                  <BlockContent
                    blocks={card._rawDescription}
                    renderContainerOnSingleChild={true}
                    sx={{
                      p: {
                        fontSize: 2,
                        opacity: 0.8,
                      },
                    }}
                  />
                )}
              </div>
            </li>
          )
        })}
      </ul>
    </>
  )
}

const GridCollection = ({ cards }) => {
  return (
    <Container>
      {cards?.length > 0 && (
        <ul
          sx={{
            listStyle: `none`,
            margin: 0,
            py: 5,
            display: `grid`,
            gridTemplateColumns: `repeat(auto-fit, 300px)`,
            gridGap: 4,
            justifyContent: `center`,
          }}
        >
          {cards.map(card => {
            if (card.internalLink?.path?.current) {
              return (
                <li
                  key={card._key}
                  sx={{
                    height: `400px`,
                    overflow: `hidden`,
                  }}
                >
                  <Link
                    sx={{
                      display: `block`,
                      position: `relative`,
                      height: `100%`,
                      "&:hover, &:active, &:focus": {
                        ".card--background": {
                          transition: `all ease-in-out 0.25s`,
                          opacity: 1,
                        },
                        ".card--content": {
                          opacity: 1,
                        },
                        ".card--button": {
                          transform: `translate3d(0,0,0)`,
                        },
                        ".card--image": {
                          transform: `scale(1.25)`,
                        },
                      },
                    }}
                    to={card.internalLink?.path?.current}
                  >
                    <div
                      className="card--content"
                      sx={{
                        position: `absolute`,
                        top: 0,
                        left: 0,
                        width: `100%`,
                        height: `100%`,
                        py: 5,
                        px: 4,
                        zIndex: 2,
                        color: `white`,
                        transition: `all ease-in-out 0.25s`,
                        opacity: 0,
                      }}
                    >
                      <h3
                        sx={{
                          color: `white`,
                          mb: 4,
                        }}
                      >
                        {card.title}
                      </h3>
                      {card._rawDescription && (
                        <BlockContent
                          blocks={card._rawDescription}
                          renderContainerOnSingleChild={true}
                          sx={{
                            p: {
                              fontSize: 2,
                            },
                          }}
                        />
                      )}
                      <span
                        className="card--button"
                        sx={{
                          mt: 4,
                          display: `inline-flex`,
                          borderRadius: `4px`,
                          border: `2px solid`,
                          borderColor: `primary`,
                          py: 2,
                          px: 3,
                          transition: `all ease-in-out 0.25s`,
                          transform: `translate3d(0,-40px,0)`,
                        }}
                      >
                        Learn more
                      </span>
                    </div>
                    <div
                      sx={{
                        backgroundColor: `background`,
                        position: `absolute`,
                        width: `100%`,
                        height: `100%`,
                      }}
                    >
                      {card.media?.image?.asset?.gatsbyImageData && (
                        <GatsbyImage
                          className="card--image"
                          image={card.media.image.asset.gatsbyImageData}
                          alt={card.media.altText}
                          sx={{
                            position: `absolute !important`,
                            top: 0,
                            left: 0,
                            width: `100%`,
                            height: `100%`,
                            transform: `scale(1.05)`,
                            transition: `all ease-in-out 0.25s`,
                          }}
                        />
                      )}
                    </div>
                    <div
                      sx={{
                        position: `absolute`,
                        bottom: 0,
                        width: `100%`,
                        height: `100%`,
                        backgroundColor: `black`,
                        opacity: 0.4,
                      }}
                    />
                    <div
                      sx={{
                        position: `absolute`,
                        padding: 4,
                        bottom: 0,
                        color: `white`,
                        fontWeight: `bold`,
                        width: `100%`,
                        fontSize: 3,
                      }}
                    >
                      <span>{card.title}</span>
                    </div>
                    <div
                      className="card--background"
                      sx={{
                        opacity: 0,
                        position: `absolute`,
                        height: `100%`,
                        width: `100%`,
                        top: 0,
                        left: 0,
                        backgroundImage: `linear-gradient(-180deg, rgba(6,6,6,0.70), rgba(6,6,6,1))`,
                        transition: `all ease-in-out 0.25s`,
                      }}
                    />
                  </Link>
                </li>
              )
            }

            return (
              <li
                key={card._key}
                sx={{
                  height: `400px`,
                  overflow: `hidden`,
                }}
              >
                <div
                  sx={{
                    display: `block`,
                    position: `relative`,
                    height: `100%`,
                    "&:hover, &:active, &:focus": {
                      ".card--background": {
                        transition: `all ease-in-out 0.25s`,
                        opacity: 1,
                      },
                      ".card--content": {
                        opacity: 1,
                      },
                      ".card--button": {
                        transform: `translate3d(0,0,0)`,
                      },
                      ".card--image": {
                        transform: `scale(1.25)`,
                      },
                    },
                  }}
                >
                  <div
                    className="card--content"
                    sx={{
                      position: `absolute`,
                      top: 0,
                      left: 0,
                      width: `100%`,
                      height: `100%`,
                      py: 5,
                      px: 4,
                      zIndex: 2,
                      color: `white`,
                      transition: `all ease-in-out 0.25s`,
                      opacity: 0,
                    }}
                  >
                    <h3
                      sx={{
                        color: `white`,
                        mb: 4,
                      }}
                    >
                      {card.title}
                    </h3>
                    {card._rawDescription && (
                      <BlockContent
                        blocks={card._rawDescription}
                        renderContainerOnSingleChild={true}
                        sx={{
                          p: {
                            fontSize: 2,
                          },
                        }}
                      />
                    )}
                  </div>
                  <div
                    sx={{
                      backgroundColor: `background`,
                      position: `absolute`,
                      width: `100%`,
                      height: `100%`,
                    }}
                  >
                    {card.media?.image?.asset?.gatsbyImageData && (
                      <GatsbyImage
                        className="card--image"
                        image={card.media.image.asset.gatsbyImageData}
                        alt={card.media.altText}
                        sx={{
                          position: `absolute !important`,
                          top: 0,
                          left: 0,
                          width: `100%`,
                          height: `100%`,
                          transform: `scale(1.05)`,
                          transition: `all ease-in-out 0.25s`,
                        }}
                      />
                    )}
                  </div>
                  <div
                    sx={{
                      position: `absolute`,
                      bottom: 0,
                      width: `100%`,
                      height: `100%`,
                      backgroundColor: `black`,
                      opacity: 0.4,
                    }}
                  />
                  <div
                    sx={{
                      position: `absolute`,
                      padding: 4,
                      bottom: 0,
                      color: `white`,
                      fontWeight: `bold`,
                      width: `100%`,
                      fontSize: 3,
                    }}
                  >
                    <span>{card.title}</span>
                  </div>
                  <div
                    className="card--background"
                    sx={{
                      opacity: 0,
                      position: `absolute`,
                      height: `100%`,
                      width: `100%`,
                      top: 0,
                      left: 0,
                      backgroundImage: `linear-gradient(-180deg, rgba(6,6,6,0.70), rgba(6,6,6,1))`,
                      transition: `all ease-in-out 0.25s`,
                    }}
                  />
                </div>
              </li>
            )
          })}
        </ul>
      )}
    </Container>
  )
}