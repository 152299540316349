import React from "react"
/** @jsx jsx */
import { jsx, Container } from "theme-ui"
import { GatsbyImage } from 'gatsby-plugin-image'
import { mediaQueries } from "../gatsby-plugin-theme-ui/media-queries"
import { SectionHeading } from "./section-heading"

export const SectionBrandList = ({ section }) => {
  if(!section) {
    return null
  }

  const { heading, brandImages } = section
  return (
    <section
      sx={{
        px: 4,
        py: [4, 4, 4, 5],
      }}
    >
      <SectionHeading heading={heading} />
      <Container>
        {brandImages?.length > 0 && (
          <ul
            sx={{
              listStyle: `none`,
              margin: 0,
              padding: 0,
              display: `flex`,
              flexDirection: `column`,
              justifyContent: `center`,
              [mediaQueries.md]: {
                flexDirection: `row`,
                flexFlow: `flow`,
              },
            }}
          >
            {brandImages.map(brandImg => {
              return (
                <li
                  key={brandImg._key}
                  sx={{
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    my: 4,
                    [mediaQueries.md]: {
                      mx: 4,
                    },
                  }}
                >
                  <GatsbyImage
                    image={brandImg.image?.asset?.gatsbyImageData}
                    alt={brandImg.altText}
                  />
                </li>
              )
            })}
          </ul>
        )}
      </Container>
    </section>
  )
}