import React from 'react'
import { graphql } from 'gatsby'
import { GatsbySeo } from "gatsby-plugin-next-seo"

import { HeroSelector } from '../components/hero-selector'
import { SectionSelector } from '../components/section-selector'
import { Header } from '../components/header'
import Layout from '../components/layout'


const Page = ({ data }) => {
  const { route, siteSettings } = data

  const { page } = route

  if (!page) {
    return null
  }
  

  const hero = page?.hero[0]
  const header = siteSettings?.header

  const seo = route?.seo || siteSettings?.seo

  const ogImages = seo?.ogImages?.map((img) => {
    return {
      url: img.image?.asset?.fluid?.src,
      alt: img.altText,
    }
  })
  
  return (
    <Layout siteSettings={siteSettings} header={header}>
      <GatsbySeo
        title={seo?.metaTitle}
        description={seo?.metaDescription}
        openGraph={ogImages}
      />
      <main>
        {hero && <HeroSelector hero={hero} />}
        {page?.sections?.length > 0 &&
          page.sections.map(section => {
            if (!section) return null
            return <SectionSelector key={section?._key} section={section} />
          })}
      </main>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query PageQuery($routeId: String!) {
    siteSettings: sanitySiteSettings {
      siteName
      siteUrl
      seo {
        metaTitle
        metaDescription
        ogImages {
          altText
          image {
            asset {
              fluid(maxWidth: 1400, maxHeight: 800) {
                src
              }
            }
          }
        }
      }
      header {
        items {
          _key
          title
          internalLink {
            path {
              current
            }
          }
          externalLink
          relativePath
        }
      }
    }
    route: sanityPageRoute(id: { eq: $routeId }) {
      page {
        title
        hero {
          ... on SanityHeroWithImages {
            _type
            heading {
              _rawTitle
              _rawSubtitle
            }
            buttons {
              _key
              _type
              label
              externalLink
              theme
              internalLink {
                path {
                  _key
                  current
                }
              }
            }
            images {
              _key
              altText
              _rawCaption(resolveReferences: { maxDepth: 2 })
              image {
                asset {
                  metadata {
                    dimensions {
                      height
                      width
                      aspectRatio
                    }
                  }
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    width: 900
                    placeholder: NONE
                  )
                }
              }
            }
          }
          ... on SanityHeroWithTextOnly {
            _type
            heading {
              _rawTitle
              _rawSubtitle
            }
            buttons {
              _key
              _type
              label
              externalLink
              theme
              internalLink {
                path {
                  _key
                  current
                }
              }
            }
          }
        }
        sections {
          ... on SanitySectionBrandList {
            _type
            _key
            heading {
              _rawTitle
              _rawSubtitle
            }
            brandImages {
              _key
              altText
              image {
                asset {
                  gatsbyImageData(layout: CONSTRAINED, width: 130)
                }
              }
            }
            settings {
              theme
            }
          }
          ... on SanitySectionCardList {
            _type
            _key
            heading {
              _rawTitle
              _rawSubtitle
            }
            cards {
              _key
              title
              _rawDescription
              internalLink {
                path {
                  current
                }
              }
              externalLink
              media {
                altText
                image {
                  asset {
                    gatsbyImageData
                  }
                }
              }
            }
            layout
            settings {
              theme
            }
          }
          ... on SanitySectionClientReviews {
            _type
            _key
            heading {
              _rawTitle
              _rawSubtitle
            }
            clientReviews {
              _key
              review
              clientName
            }
            settings {
              theme
            }
          }
          ... on SanitySectionGallery {
            _type
            _key
            heading {
              _rawTitle
              _rawSubtitle
            }
            images {
              altText
              image {
                asset {
                  metadata {
                    dimensions {
                      height
                      width
                      aspectRatio
                    }
                  }
                  gatsbyImageData
                  lightBox: gatsbyImageData(layout: CONSTRAINED, width: 900)
                }
              }
            }
            settings {
              theme
            }
          }
          ... on SanitySectionCallToAction {
            _type
            _key
            heading {
              _rawTitle
              _rawSubtitle
            }
            buttons {
              ... on SanityButtonLink {
                _key
                _type
                label
                externalLink
                theme
                internalLink {
                  path {
                    _key
                    current
                  }
                }
              }
            }
            settings {
              theme
            }
          }
          ... on SanitySectionContactForm {
            _type
            _key
            heading {
              _rawTitle
              _rawSubtitle
            }
            form {
              ... on SanityForm {
                title
                _rawFields
              }
            }
            settings {
              theme
            }
          }
          ... on SanitySectionFaq {
            _type
            _key
            heading {
              _rawTitle
              _rawSubtitle
            }
            faqs {
              _key
              question
              _rawAnswer
            }
            settings {
              theme
            }
          }
          ... on SanitySectionPricing {
            _type
            _key
            heading {
              _rawTitle
              _rawSubtitle
            }
            pricingCards {
              _key
              title
              _rawDescription
              price
              pricingType
              features
            }
            settings {
              theme
            }
          }
        }
      }
      seo {
        metaTitle
        metaDescription
        ogImages {
          altText
          image {
            asset {
              fluid(maxWidth: 1400, maxHeight: 800) {
                src
              }
            }
          }
        }
      }
    }
  }
`