import React from "react"
/** @jsx jsx */
import { jsx, Container } from "theme-ui"
import { breakpoints } from "../gatsby-plugin-theme-ui/breakpoints"
import { mediaQueries } from "../gatsby-plugin-theme-ui/media-queries"
import { SectionHeading } from "./section-heading"

export const SectionClientReviews = ({ section }) => {
  if (!section) {
    return null
  }

  const { heading, clientReviews } = section
  return (
    <section
      sx={{
        px: 4,
        py: [4, 4, 4, 5],
      }}
    >
      <SectionHeading heading={heading} />
      <Container>
        {clientReviews?.length > 0 && (
          <ul
            sx={{
              listStyle: `none`,
              margin: 0,
              padding: 0,
              display: `flex`,
              flexDirection: `column`,
              justifyContent: `center`,
              mt: 4,
              [mediaQueries.md]: {
                flexDirection: `row`,
                flexFlow: `flow`,
                mt: 5,
              },
            }}
          >
            {clientReviews.map(clientReview => {
              return (
                <li
                  key={clientReview._key}
                  sx={{
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    py: 5,
                    px: 4,
                    maxWidth: breakpoints.md,
                    ":nth-of-type(odd)": {
                      bg: `primary`,
                      [mediaQueries.md]: {
                        bg: "unset",
                      },
                    },
                    [mediaQueries.md]: {
                      px: 4,
                      ":nth-of-type(even)": {
                        bg: `primary`,
                      },
                    },
                  }}
                >
                  <div>
                    <p sx={{ mt: 0 }}>"{clientReview.review}"</p>
                    <p>— {clientReview.clientName}</p>
                  </div>
                </li>
              )
            })}
          </ul>
        )}
      </Container>
    </section>
  )
}
