import { Link } from 'gatsby'
import React from 'react'
/** @jsx jsx */
import { jsx } from 'theme-ui'

export const ButtonLink = ({ button, className }) => {
  if(!button) {
    return null
  }

  const { label, externalLink, internalLink, theme } = button

  const buttonVariant = theme ? `buttons.${theme}` : `buttons.light`

  const buttonStyle = {
    textDecoration: `none`,
    display: `block`,
    py: [2],
    px: [3],
    fontWeight: `bold`,
    fontSize: 1,
    variant: buttonVariant,
    textAlign: `center`
  }
  
  if(internalLink?.path?.current) {
    return (
      <Link to={internalLink.path.current} sx={{ ...buttonStyle }} className={className}>{label}</Link>
    )
  } else if(externalLink) {
    return (
      <a
        href={externalLink}
        rel="nofollow noreferrer noopener"
        target="_blank"
        className={className}
      >
        {label}
      </a>
    )
  } else {
    return (
      <a sx={{ ...buttonStyle }} className={className}>
        {label}
      </a>
    )
  }
}